<template>
  <div class="register-user-mgr-form-contianer">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <col2-item label="设备名称" :text="form.name" />
          <col2-item label="维保周期" :text="period" />
          <col2-item label="设备编号" :text="form.serialNum" />
          <col2-item label="维保日期" :text="form.maintDate" />
          <col2-item label="归属项目" :text="form.communityName" />
          <col2-item label="维保单位" :text="form.maintCompany" />
          <col2-item label="所属组团" :text="form.area" />
          <col2-item label="负责人" :text="chargerUserName" />
          <col2-item label="安装位置" :text="form.address" />
        </col2-block>
        <div>
        <col2-block title="维保内容">
        <div style="display: flex;">
          <p class="content">
            维保内容:
          </p>
          <p class="info">
            <!-- 新工单 -->
            <ul>
              <li v-for="(content,index) in newOrderListV" :key="index">
                {{ index+1 }}、工单编号 <v-button type="text" :text="content.serialNumber" @click="orderClick(content)"></v-button>
                ({{newOrderStatusMap[content.orderStatus]}})
              </li>
            </ul>
            <!-- <span style="padding-left: 10px;" v-for="(item,index) in newOrderListV" :key="index">
              <span>2134</span><br/>
            </span> -->
          </p>
        </div>
        </col2-block>
        </div>
        <col2-block title="维保反馈">
          <col2-item :span="24" label="执行方式" :text="form.finishTypeShowName" />
          <col2-item :span="24" label="维保状态" :text="form.statusShowName" />
          <col2-item :span="24" label="执行人" :text="executorName" />
          <col2-item :span="24" label="维保开始时间" :text="form.startTime" />
          <col2-item :span="24" label="维保完成时间" :text="form.finishTime" />
          <div>
            <div>
              <p class="content">
                维保内容:
              </p>
              <p>
                <span style="display:inline-block;padding: 12px 38px;" v-for="(item,index) in executeMaintenanceContent" :key="index">
                  <span>{{item}}</span><br/>
                </span>
              </p>
            </div>
            <div class="clearBoth">
              <p class="content">
                维保照片:
              </p>
              <p>
                <v-preview class="imgs" :imgs="maintImages" :textMode="false" buttonText="查看维保照片"></v-preview>
              </p>
            </div>
            <div class="clearBoth">
              <p class="content" >
                备注:
              </p>
              <p  class="detail">{{form.remarks}}</p>
            </div>
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { getDetailURL } from './api'
import vPreview from 'components/control/v-preview/v-preview'
import { Col2Block, Col2Detail, Col2Item } from '@/components/bussiness'
import {  orderStatusMap, newOrderStatusMap } from './map'

export default {
  name: 'MaintenanceLogListForm',
  components: {
    Col2Block,
    Col2Detail,
    Col2Item,
    vPreview
  },
  data () {
    return {
      orgInfoList: [],
      queryUrl: getDetailURL,
      form: {
        'name': ''
      },
      period: '',
      chargerUserName: '',
      isRelateOrg: '',
      communityName: '',
      orgsModalVisible: false,
      userSourceText: '',
      planMaintenanceContent: [],
      executorName: '',
      executeMaintenanceContent: [],
      maintImages: [],
      newOrderListV:[],
      orderStatusMap,
      newOrderStatusMap,
      newOrderV3: undefined
    }
  },
  mounted () {
    this.$refs.form.getData({ id: this.$route.query.id })
    this.$setBreadcrumb('维保日志详情')
  },
  methods: {
    update (data) {
      /* eslint-disable */
      [
        'id',
        'name',            
        'serialNum',            
        'maintDate',         
        'communityName',          
        'maintCompany',   
        'area',  
        'chargerUserName', 
        'chargerUserMobile',
        'address',
        'finishTypeShowName',
        'statusShowName',
        'executorName',
        'executorMobile',
        'startTime',
        'finishTime',
        'remarks'
      ].forEach(key => {
        if (data.deviceMaintLog[key]) {
          this.form[key] = data.deviceMaintLog[key] 
        }
        let name = this.form.chargerUserName ? this.form.chargerUserName : ''
        let mobile = this.form.chargerUserMobile ? `（${this.form.chargerUserMobile}）` : ''
        this.chargerUserName = name ? name + mobile : ''
        let executorName = this.form.executorName ? this.form.executorName : ''
        let executorMobile = this.form.executorMobile ? `（${this.form.executorMobile}）` : ''
        this.executorName = executorName ? executorName + executorMobile : ''
      })
      // data.planMaintContents.forEach((item, i) => {
      //   this.planMaintenanceContent.push(i + 1 + '、' + item)
      // })
      data.newOrderListV.forEach((item) => {
        this.newOrderListV.push(item)
      })
      data.executeMaintContents.forEach((item, i) => {
        this.executeMaintenanceContent.push(i + 1 + '、' + item)
      })
      this.period = data.period
      data.maintImages.map(item => {
        this.maintImages.push(
          {
            src: item
          }
        )
      })
      this.newOrderV3 = data.newOrderV3
    },
    // 跳转到工单页面
    async orderClick (order) {
      // newOrderV3为true跳转工单3.0
      if (this.newOrderV3) {
        const menuInfo = this.$store.getters['menuStore/getMenuInfo'](947241)
        this.$router.push({
          name:'iframePanel',
          query:{
            pid:1,
            cid:3400,
            fid:94751,
            sid:947241,
            src: menuInfo.item.url,
            orderId: order.orderId,
            isReplace: 1
          }
        })
      } else {
        // 新工单
        this.$router.push({
          name: 'orderSchedulingForm',
          query: {
            id: order.orderId,
          }
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .content {
    float:left;
    margin-left: 86px;
    font-weight: bold;
    color: #333;
    text-align: right;
  }
  .imgs {
    display: inline-block;
    margin-left: 38px;
  }
  .info {
    margin-left: 10px;
  }
  .detail {
    margin-left: 175px;
    padding-top: 15px;
  }
  .clearBoth {
    clear: both;
  }
</style>
