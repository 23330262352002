import { generateMapByOpts } from 'common/utils'
// 订单状态
const orderStatusOps = [
  {
    text: '待接收',
    value: 1
  },
  {
    text: '处理中',
    value: 2
  },
  {
    text: '已处理',
    value: 3
  },
  {
    text: '已评价',
    value: 4
  },
  {
    text: '已关闭',
    value: 5
  },
  {
    text: '待处理',
    value: 6
  }
]

// 新订单状态
const newOrderStatusOps = [
  {
    text: '待接收',
    value: 1
  },
  {
    text: '待处理',
    value: 2
  },
  {
    text: '超时未接收',
    value: 3
  },
  {
    text: '超时未处理',
    value: 4
  },
  {
    text: '处理中',
    value: 5
  },
  {
    text: '已处理',
    value: 6
  },
  {
    text: '已完成',
    value: 7
  },
  {
    text: '已关闭',
    value: 8
  },
  {
    text: '超时处理中',
    value: 9
  },
]
export const orderStatusMap = generateMapByOpts(orderStatusOps)
export const newOrderStatusMap = generateMapByOpts(newOrderStatusOps)
