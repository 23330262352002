// 获取列表
const getListURL = `${API_CONFIG.baseURL}smDeviceMaintLogAction!maintLogList.action`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}smDeviceMaintLogAction!exportMaintLogToExcel.action`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 生成维保计划
const generateMaintenanceURL = `${API_CONFIG.baseURL}smDeviceAction!generateDeviceMaint.action`
// 详情
// const getDetailURL = `${API_CONFIG.baseURL}smDeviceMaintLogAction!queryMaintLog.action`
const getDetailURL = `${API_CONFIG.butlerBaseURL}devicePatrol/findDeviceMaintLogById`

export {
  getListURL,
  exportListURL,
  batchURL,
  generateMaintenanceURL,
  getDetailURL
}
