var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-user-mgr-form-contianer" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: { form: _vm.form, queryUrl: _vm.queryUrl },
          on: { update: _vm.update },
        },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c("col2-item", {
                    attrs: { label: "设备名称", text: _vm.form.name },
                  }),
                  _c("col2-item", {
                    attrs: { label: "维保周期", text: _vm.period },
                  }),
                  _c("col2-item", {
                    attrs: { label: "设备编号", text: _vm.form.serialNum },
                  }),
                  _c("col2-item", {
                    attrs: { label: "维保日期", text: _vm.form.maintDate },
                  }),
                  _c("col2-item", {
                    attrs: { label: "归属项目", text: _vm.form.communityName },
                  }),
                  _c("col2-item", {
                    attrs: { label: "维保单位", text: _vm.form.maintCompany },
                  }),
                  _c("col2-item", {
                    attrs: { label: "所属组团", text: _vm.form.area },
                  }),
                  _c("col2-item", {
                    attrs: { label: "负责人", text: _vm.chargerUserName },
                  }),
                  _c("col2-item", {
                    attrs: { label: "安装位置", text: _vm.form.address },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("col2-block", { attrs: { title: "维保内容" } }, [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("p", { staticClass: "content" }, [
                        _vm._v("\n          维保内容:\n        "),
                      ]),
                      _c("p", { staticClass: "info" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.newOrderListV, function (content, index) {
                            return _c(
                              "li",
                              { key: index },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(index + 1) +
                                    "、工单编号 "
                                ),
                                _c("v-button", {
                                  attrs: {
                                    type: "text",
                                    text: content.serialNumber,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.orderClick(content)
                                    },
                                  },
                                }),
                                _vm._v(
                                  "\n              (" +
                                    _vm._s(
                                      _vm.newOrderStatusMap[content.orderStatus]
                                    ) +
                                    ")\n            "
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "维保反馈" } },
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "执行方式",
                      text: _vm.form.finishTypeShowName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "维保状态",
                      text: _vm.form.statusShowName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "执行人",
                      text: _vm.executorName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "维保开始时间",
                      text: _vm.form.startTime,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "维保完成时间",
                      text: _vm.form.finishTime,
                    },
                  }),
                  _c("div", [
                    _c("div", [
                      _c("p", { staticClass: "content" }, [
                        _vm._v("\n              维保内容:\n            "),
                      ]),
                      _c(
                        "p",
                        _vm._l(
                          _vm.executeMaintenanceContent,
                          function (item, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                staticStyle: {
                                  display: "inline-block",
                                  padding: "12px 38px",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(item))]), _c("br")]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "clearBoth" }, [
                      _c("p", { staticClass: "content" }, [
                        _vm._v("\n              维保照片:\n            "),
                      ]),
                      _c(
                        "p",
                        [
                          _c("v-preview", {
                            staticClass: "imgs",
                            attrs: {
                              imgs: _vm.maintImages,
                              textMode: false,
                              buttonText: "查看维保照片",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "clearBoth" }, [
                      _c("p", { staticClass: "content" }, [
                        _vm._v("\n              备注:\n            "),
                      ]),
                      _c("p", { staticClass: "detail" }, [
                        _vm._v(_vm._s(_vm.form.remarks)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }